<template>
   <Layout>
      <PageHeader :title="title" :items="items"></PageHeader>
      <div class="row">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <b-form enctype="multipart/form-data" ref="protectedContent" @submit.prevent="
                  $route.name == 'add-protected-content' ? submitData : ''">
                     <b-form-group id="input-group-1" label-for="input-1">
                        <label for="content_type">Content Type</label>
                        <b-form-select @input="changeContentType" id="content_type" v-model="form.content_type"
                           :options="contentType" value-field="value" text-field="text">
                        </b-form-select>
                     </b-form-group>

                     <template v-if="form.content_type != null && form.content_type != ''">
                        <b-form-group id="input-group-2" label-for="input-2" :label="
                          form.content_type == 'forum'
                            ? 'Forum'
                            : form.content_type == 'live_event'
                            ? 'Live Event'
                            : ''
                        ">
                           <template v-if="form.content_type != null && form.content_type != ''">
                              <b-form-select id="content_type" :options="contentOpt" value-field="id" text-field="title">
                              </b-form-select>
                           </template>
                        </b-form-group>
                     </template>

                     <b-form-group>
                        <b-form-checkbox>Use Different Password</b-form-checkbox>
                     </b-form-group>

                     <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData">Save Data
                     </b-button>
                  </b-form>
               </div>
            </div>
         </div>
      </div>
   </Layout>
</template>
 
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import protectedContent from "../../../mixins/ModuleJs/protected-content";

export default {
   components: {
      Layout,
      PageHeader,
   },
   mixins: [MixinRequest, protectedContent],
   data() {
      return {
         submitted: false,
         title: "Add Protected Content",
         items: [
            {
               text: "Back",
               href: "/protected-content",
            },
            {
               text: "Data",
            },
         ],
      };
   },
};
</script>
 