import {
   protected_content,
   live_event,
   forum
} from "../../js/path";

export default {
   data() {
      return {
         pageNo: null,
         fields: [{
               key: "id",
               label: "ID",
               sortable: true,
            },
            {
               key: "content_id",
               label: 'Content ID'
            },
            {
               key: "content_type",
               label: "Content Type",
            },
            {
               key: "default_username",
               label: 'Default Username'
            },
            {
               key: 'default_password',
               label: 'Default Password'
            },
            {
               key: 'total_users_registered',
               label: 'Total Users Registered'
            },
            {
               key: 'use_different_passwords',
               label: 'Use Different Passwords'
            },
            {
               key: 'upload_users',
               label: 'Upload Users'
            },
            {
               key: 'delete'
            }
         ],
         params: "",
         filter: null,
         filterOn: [],
         tableData: [],
         sortBy: "id",
         sortDesc: true,
         key: 0,
         currentPage: 1,
         activeTab: "all",
         contentType: [{
               value: 'forum',
               text: 'Forum'
            },
            {
               value: 'live_event',
               text: 'Live Event'
            }
         ],
         contentOpt: [],
         form: {
            content_type: '',

         }
      };
   },
   methods: {
      search(event) {
         if (this.filter.length > 1) {
            if (event.keyCode == 13) {
               this.fetchData(this.activeTab);
            }
         } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      onFiltered(filteredItems) {
         this.totalRows = filteredItems.length;
         this.currentPage = 1;
      },
      async fetchData(txt) {
         let url = null;
         if (txt == "trash") {
            this.activeTab = txt;
            if (this.filter != null && this.filter != "") {
               url =
                  protected_content.fetchProtectedContent + "?filter=" + txt + "&search=" + this.filter;
            } else {
               url = protected_content.fetchProtectedContent + "?filter=" + txt;
            }
         } else if (txt == "all") {
            this.activeTab = txt;
            if (this.filter != null && this.filter != "") {
               url =
                  protected_content.fetchProtectedContent + "?filter=" + txt + "&search=" + this.filter;
            } else {
               url = protected_content.fetchProtectedContent + "?filter=" + txt;
            }
         }
         url += this.params;
         this.$store.commit("loader/updateStatus", true);
         try {
            const data = await this.getRequest(url);
            if (data.status) {
               const responseData = data.response;
               this.tableData = responseData;
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      changeContentType(val) {
         this.fetchContent(val);
      },
      async fetchContent(val) {
         if (val == 'live_event') {
            const url = live_event.fetchAllLive_event;
            this.$store.commit('loader/updateStatus', true);
            const data = await this.getRequest(url);
            if (data.status) {
               this.contentOpt = data.response.data;
               this.$store.commit('loader/updateStatus', false);
            }
         } else if (val == 'forum') {
            const url = forum.fetchAllActiveForum;
            this.$store.commit('loader/updateStatus', true);
            const data = await this.getRequest(url);
            if (data.status) {
               this.contentOpt = data.response.data;
               this.$store.commit('loader/updateStatus', false);
            }
         }
      }
   },
   watch: {
      currentPage: {
         handler: function (value) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
         },
      },
   },
};